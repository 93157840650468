import React from "react";
import CartContext from "../../context/CartContext";
import { Link } from "gatsby";

import * as classes from "./CartIcon.module.css";

const CartIcon = () => {
  const { checkout } = React.useContext(CartContext);
  let totalQuantity = 0;
  if (checkout) {
    checkout.lineItems.forEach((lineItem) => {
      totalQuantity = totalQuantity + lineItem.quantity;
    });
  }

  return (
    <>
      {totalQuantity !== 0 && (
        <Link to="/cart" className={classes.cartIcon}>
          Cart ({totalQuantity} item(s) / ${checkout?.totalPrice || "0.00"})
        </Link>
      )}
    </>
  );
};

export default CartIcon;
