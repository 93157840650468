// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-foryou-js": () => import("./../../../src/pages/foryou.js" /* webpackChunkName: "component---src-pages-foryou-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-stickers-js": () => import("./../../../src/pages/stickers.js" /* webpackChunkName: "component---src-pages-stickers-js" */),
  "component---src-pages-sydneyartists-js": () => import("./../../../src/pages/sydneyartists.js" /* webpackChunkName: "component---src-pages-sydneyartists-js" */),
  "component---src-pages-world-js": () => import("./../../../src/pages/world.js" /* webpackChunkName: "component---src-pages-world-js" */),
  "component---src-templates-lookbook-js": () => import("./../../../src/templates/lookbook.js" /* webpackChunkName: "component---src-templates-lookbook-js" */),
  "component---src-templates-news-item-js": () => import("./../../../src/templates/news-item.js" /* webpackChunkName: "component---src-templates-news-item-js" */),
  "component---src-templates-product-item-js": () => import("./../../../src/templates/product-item.js" /* webpackChunkName: "component---src-templates-product-item-js" */)
}

