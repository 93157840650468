// extracted by mini-css-extract-plugin
export var burgerContainer = "Header-module--burgerContainer--37GY6";
export var logoContainer = "Header-module--logoContainer--lN6fB";
export var show = "Header-module--show--3FRF8";
export var backBtn = "Header-module--backBtn--2HXXv";
export var logo = "Header-module--logo--2EZVS";
export var logoOpen = "Header-module--logoOpen--1exI2";
export var navContainer = "Header-module--navContainer--1rSmz";
export var linkDisabled = "Header-module--linkDisabled--1F5W2";
export var decorations = "Header-module--decorations--rYrIi";
export var svgBinoc = "Header-module--svgBinoc--dc_bx";
export var spin = "Header-module--spin--1oAwD";
export var navOpen = "Header-module--navOpen--1LQ4P";
export var openNav = "Header-module--openNav--1HTYm";
export var navHide = "Header-module--navHide--qhKT4";
export var closeNav = "Header-module--closeNav--11YDQ";
export var navIcon4 = "Header-module--navIcon4--1LU0x";
export var open = "Header-module--open--u6ug7";