import React, { useState, useEffect } from "react";

import Transition from "../components/Transition/Transition";
import Header from "../components/Header/Header";
import CartIcon from "../components/Cart/CartIcon";

import "./index.css";

const Layout = (props) => {
  const [showBack, setShowBack] = useState(false);
  const [backLink, setBackLink] = useState("/shop");
  const [isLanding, setIsLanding] = useState(false);

  useEffect(() => {
    const linkContainsShop = props.location.pathname.includes("/shop/");
    const linkContainsNews = props.location.pathname.includes("/news/");
    const linkContainsStickers = props.location.pathname.includes("/stickers");
    const linkIsLanding = props.path === "/";
    const moreThanSix = props.location.pathname.length > 6;

    const isBackAvailable =
      (linkContainsShop && moreThanSix) ||
      (linkContainsNews && moreThanSix) ||
      (linkContainsStickers && moreThanSix);

    if (linkContainsShop) {
      setBackLink("/shop");
    } else if (linkContainsNews) {
      setBackLink("/news");
    } else {
      setBackLink("/shop");
    }

    setIsLanding(linkIsLanding);

    setShowBack(isBackAvailable);
  }, [props.location.pathname, props.path, setShowBack]);

  return (
    <>
      <Header
        isBackAvailable={showBack}
        backLink={backLink}
        setShowBack={setShowBack}
        isLanding={isLanding}
      />
      <Transition location={props.location}>
        <CartIcon />
        <main>{props.children}</main>
        <footer>© 2021 Goriot.</footer>
      </Transition>
    </>
  );
};

export default Layout;
