import React, { Fragment, useState, useEffect } from "react";
import Transition from "react-transition-group/Transition";
import { Link } from "gatsby";

import Logo from "../../svg/Logo.svg";
import TripleLogo from "../../svg/TripleLogo.svg";
import Binoc from "../../svg/Binoc.svg";
import Back from "../../svg/Back.svg";

import * as classes from "./Header.module.css";

const Header = (props) => {
  const [showNav, setShowNav] = useState(false);

  const showNavHandler = () => {
    setShowNav(true);
  };

  const closeNavHandler = () => {
    setShowNav(false);
  };

  // Scroll lock when lightbox is open
  useEffect(() => {
    if (showNav) {
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
    } else {
      document.getElementsByTagName("body")[0].style = "";
    }

    return () => {
      document.getElementsByTagName("body")[0].style = "";
    };
  }, [showNav]);

  const burgerClasses = showNav
    ? `${classes.navIcon4} ${classes.open}`
    : `${classes.navIcon4}`;

  const logoClasses = showNav
    ? `${classes.logo} ${classes.logoOpen}`
    : `${classes.logo}`;

  let logoContainerClass;
  if (!showNav) {
    logoContainerClass = props.isBackAvailable
      ? `${classes.logoContainer} ${classes.show}`
      : `${classes.logoContainer}`;
  } else {
    logoContainerClass = classes.logoContainer;
  }

  let backBtnClass;
  if (!showNav) {
    backBtnClass = props.isBackAvailable
      ? `${classes.backBtn} ${classes.show}`
      : `${classes.backBtn}`;
  } else {
    backBtnClass = classes.backBtn;
  }

  return (
    <Fragment>
      <header>
        <div className={logoContainerClass}>
          <Link to={props.backLink} className={backBtnClass}>
            <Back />
          </Link>
          <Link to="/">
            <Logo className={logoClasses} onClick={closeNavHandler} />
          </Link>
        </div>

        <div className={classes.burgerContainer}>
          <button
            className={burgerClasses}
            onClick={!showNav ? showNavHandler : closeNavHandler}
          >
            <span></span>
            <span></span>
          </button>
        </div>
      </header>

      <Transition in={showNav} timeout={500} mountOnEnter unmountOnExit>
        {(state) => {
          const navContainerClasses = [
            `${classes.navContainer}`,
            state === "entering"
              ? `${classes.navOpen}`
              : state === "exiting"
              ? `${classes.navHide}`
              : null,
          ];

          return (
            <div className={navContainerClasses.join(" ")}>
              <nav>
                <Link onClick={closeNavHandler} to="/shop">
                  Shop
                </Link>
                <Link
                  className={classes.linkDisabled}
                  onClick={closeNavHandler}
                  to="/news"
                >
                  News
                </Link>
                <Link onClick={closeNavHandler} to="/about">
                  About
                </Link>
                <Link onClick={closeNavHandler} to="/lookbook/2020SS-1">
                  Lookbook
                </Link>
                <Link onClick={closeNavHandler} to="/sydneyartists">
                  Sydney Music
                </Link>
                <Link onClick={closeNavHandler} to="/world">
                  World Music
                </Link>
                <Link onClick={closeNavHandler} to="/faq">
                  FAQ
                </Link>
                <a
                  onClick={closeNavHandler}
                  href="https://www.instagram.com/goriot_official/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Instagram
                </a>
                <Link onClick={closeNavHandler} to="/foryou">
                  For You
                </Link>
              </nav>
              <div className={classes.decorations}>
                <Binoc className={classes.svgBinoc} />
                <div>
                  <p>
                    If you came this way,
                    <br />
                    Taking any route, starting from anywhere,
                    <br />
                    At any time or at any season,
                    <br />
                    It would always be the same
                  </p>
                </div>
                <div>
                  <TripleLogo />
                </div>
                <div>
                  <p>© Goriot 2021.</p>
                </div>
              </div>
            </div>
          );
        }}
      </Transition>
    </Fragment>
  );
};

export default Header;
